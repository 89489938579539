import React from "react"
import { Flex, Text } from "theme-ui"
import { LoginForm } from "../../account/pages/sign-in"

const LoginModal = ({ afterSubmit }) => {
  return (
    <Flex sx={{ maxWidth: "375px", p: 1, flexDirection: "column" }}>
      <Text sx={{ fontSize: "21px", marginY: "16px", paddingRight: "20%" }}>
        Sign into your account to add to your wishlist
      </Text>
      <LoginForm showSignup={true} afterSubmit={afterSubmit} />
    </Flex>
  )
}

export default LoginModal
