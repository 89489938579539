import * as React from "react"
import { Box, BoxProps, Flex, FlexProps, Text } from "theme-ui"

export const ProgressSteps: React.FC<FlexProps> = ({
  sx,
  children,
  ...flexProps
}) => {
  return (
    <Flex
      as="ol"
      sx={{
        color: "grayscale.600",
        fontSize: "sm",
        listStyleType: "none",
        cursor: "default",
        "li::after": {
          content: '"-"',
          display: "inline-block",
          marginInline: 1,
        },
        "li:last-child::after": {
          display: "none",
        },
        ...sx,
      }}
      {...flexProps}
    >
      {children}
    </Flex>
  )
}

export interface ProgressStepsItemProps extends BoxProps {
  isActive?: boolean
  isClickable?: boolean
}

export const ProgressStepsItem: React.FC<ProgressStepsItemProps> = ({
  sx,
  children,
  isActive,
  isClickable,
  ...boxProps
}) => {
  return (
    <Box as="li" sx={{ ...sx }} {...boxProps}>
      <Text
        sx={{
          color: isActive ? "primary" : "grayscale.600",
          cursor: isClickable && !isActive && "pointer",
          transition: "color .2s",
          ":hover": {
            color: isClickable && !isActive && "primary",
          },
        }}
      >
        {children}
      </Text>
    </Box>
  )
}
