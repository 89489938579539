import { Cart } from "@medusajs/medusa"

export type CartCheckoutState =
  | "details"
  | "shipping"
  | "payment"
  | "review"
  | "complete"

export const restoreCartCheckoutState = (
  cart: Readonly<Omit<Cart, "refundable_amount" | "refunded_total">>
): CartCheckoutState => {
  let checkoutState: CartCheckoutState = "details"

  if (!cart?.id) {
    return checkoutState
  }

  if (
    (cart.shipping_address?.country_code === "us" ||
      cart.shipping_address?.country_code === "ca") &&
    !cart.shipping_address?.province
  ) {
    return checkoutState
  }

  if (
    cart.email &&
    cart.shipping_address?.first_name &&
    cart.shipping_address?.last_name &&
    cart.shipping_address?.address_1 &&
    cart.shipping_address?.postal_code &&
    cart.shipping_address?.city &&
    cart.shipping_address?.phone &&
    cart.shipping_address?.country_code
  ) {
    checkoutState = "shipping"
  } else {
    return checkoutState
  }

  if (cart.items.some((i) => i.is_giftcard)) {
    checkoutState = "details"
  }

  return checkoutState
}
